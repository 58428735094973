// Fonts
@import url("https://fonts.googleapis.com/css?family=Raleway:300,400,600");

// Variables

// Body
$body-bg: #f5f8fa;

// Typography
$font-family-sans-serif: "Raleway", sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

// Bootstrap
@import "~bootstrap/dist/css/bootstrap.css";

@import "~animate.css/animate.min.css";

.login {
    // margin-top: 2rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
    // padding: 0 0 30px 0;
    padding: 0 0 0 0;
    position: relative;
    z-index: 3;

    .login-crd {
        // box-shadow: 0 0 30px rgba(214, 215, 216, 0.6);
        // padding: 30px;
        box-shadow: 10px 5px 12px -4px #00000045;
        // border: 1px dashed #39312f;
        border: 2px solid #39312fb5;

        .card-header {
            font-size: 1.6rem;
            text-align: center;
            // background-color:rgba(255, 88, 33, 0.2);
            border-bottom: 2px solid #39312fb5;
        }

        input,
        textarea {
            border-radius: 0;
            box-shadow: none;
            font-size: 14px;
        }

        input::focus,
        textarea::focus {
            background-color: #e96b56;
        }

        input {
            padding: 20px 15px;
        }

        textarea {
            padding: 12px 15px;
        }

        button[type="submit"] {
            background: #e96b56;
            // border-radius: 50px;
            border: 0;
            box-shadow: 2px 6px 8px -2px #5f1002cf;
            outline: 0;
            border-radius: 0.3rem;
            padding: 10px 24px;
            color: #fff;
            transition: 0.4s;
        }

        button[type="submit"]:hover {
            background: #e6573f;
            transform: translateY(-2px);
            border: 0;
            box-shadow: 2px 6px 8px -2px #5f1002cf;
            outline: 0;
        }

        .btn-with-icon {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 1rem;
            font-weight: bold;
            padding: 11px 52px 11px 19px;
            padding-left: 0 !important;
            padding-right: 52px !important;
            max-width: 220px;
            height: 48px;
            color: #fff;
            background: #0082a8;
            overflow: hidden;
            border-radius: 5px;
        }

        @media (min-width: 992px) {
            .btn-with-icon {
                max-width: 289px;
                padding: 11px 52px 11px 38px;
                height: 58px;
            }
        }

        .btn-with-icon::after {
            content: "";
            height: 100%;
            width: 52px;
            background: #00a5bf
                url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHhtbG5zOnN2Z2pzPSJodHRwOi8vc3ZnanMuY29tL3N2Z2pzIiB3aWR0aD0iNTEyIiBoZWlnaHQ9IjUxMiIgeD0iMCIgeT0iMCIgdmlld0JveD0iMCAwIDUxMiA1MTIiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDUxMiA1MTIiIHhtbDpzcGFjZT0icHJlc2VydmUiIGNsYXNzPSIiPjxnPgo8ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgoJPGc+CgkJPHBhdGggZD0iTTM2Ny45NTQsMjEzLjU4OEwxNjAuNjcsNS44NzJjLTcuODA0LTcuODE5LTIwLjQ2Ny03LjgzMS0yOC4yODQtMC4wMjljLTcuODE5LDcuODAyLTcuODMyLDIwLjQ2NS0wLjAzLDI4LjI4NCAgICBsMjA3LjI5OSwyMDcuNzMxYzcuNzk4LDcuNzk4LDcuNzk4LDIwLjQ4Ni0wLjAxNSwyOC4yOTlMMTMyLjM1Niw0NzcuODczYy03LjgwMiw3LjgxOS03Ljc4OSwyMC40ODIsMC4wMywyOC4yODQgICAgYzMuOTAzLDMuODk2LDkuMDE2LDUuODQzLDE0LjEyNyw1Ljg0M2M1LjEyNSwwLDEwLjI1LTEuOTU4LDE0LjE1Ny01Ljg3M2wyMDcuMjY5LTIwNy43MDEgICAgQzM5MS4zMzMsMjc1LjAzMiwzOTEuMzMzLDIzNi45NjcsMzY3Ljk1NCwyMTMuNTg4eiIgZmlsbD0iI2ZmZmZmZiIgZGF0YS1vcmlnaW5hbD0iIzAwMDAwMCIgc3R5bGU9IiIgY2xhc3M9IiI+PC9wYXRoPgoJPC9nPgo8L2c+CjxnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjwvZz4KPGcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPC9nPgo8ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8L2c+CjxnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjwvZz4KPGcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPC9nPgo8ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8L2c+CjxnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjwvZz4KPGcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPC9nPgo8ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8L2c+CjxnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjwvZz4KPGcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPC9nPgo8ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8L2c+CjxnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjwvZz4KPGcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPC9nPgo8ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8L2c+CjwvZz48L3N2Zz4=)
                no-repeat center;
            background-size: 16px 16px;
            position: absolute;
            top: 0;
            right: 0;
            transition: width 200ms ease-in-out;
        }

        .btn-with-icon:hover,
        .btn-with-icon:focus {
            color: #fff;
            text-decoration: none;
            background: #007294;
        }

        .btn-with-icon:hover:after,
        .btn-with-icon:focus:after {
            width: 55px;
        }

        [disabled].btn-with-icon,
        .disabled.btn-with-icon {
            opacity: 0.6;
            cursor: default;
            pointer-events: none;
        }
    }
}
